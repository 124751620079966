// Generated by Framer (3def70c)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Card from "https://framerusercontent.com/modules/ijmst3wu2lDzeo01qIWB/RsMDr8Ba8Y4AYuiBpVYo/AUN51nzRP.js";
import * as localizedValues from "./Df9FqP0tu-0.js";
const CardFonts = getFonts(Card);

const cycleOrder = ["eMuKVzvgJ", "Kq8NtCRh2", "kHNnaqIzo"];

const serializationHash = "framer-oYGjw"

const variantClassNames = {eMuKVzvgJ: "framer-v-995dky", kHNnaqIzo: "framer-v-1ldnrtz", Kq8NtCRh2: "framer-v-1mhnes2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Medium: "Kq8NtCRh2", Open: "kHNnaqIzo", Small: "eMuKVzvgJ"}

const getProps = ({height, id, name1, newOpen, portrait, role, width, ...props}) => { return {...props, BpqMX3cRk: portrait ?? props.BpqMX3cRk ?? {src: "https://framerusercontent.com/images/MOjeMBfvtTEBPdcYZSWnOfyjkY.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/MOjeMBfvtTEBPdcYZSWnOfyjkY.png?scale-down-to=1024 944w,https://framerusercontent.com/images/MOjeMBfvtTEBPdcYZSWnOfyjkY.png 1180w"}, Kfe0qRoJD: newOpen ?? props.Kfe0qRoJD, N8vYS_fKJ: role ?? props.N8vYS_fKJ ?? "PRESIDENT, PRODUCT AND BUSINESS, STRIPE", pZ18UUIqg: name1 ?? props.pZ18UUIqg ?? "Will Gaybrick", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "eMuKVzvgJ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;newOpen?: any;portrait?: {src: string; srcSet?: string};role?: string;name1?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Kfe0qRoJD, BpqMX3cRk, N8vYS_fKJ, pZ18UUIqg, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "eMuKVzvgJ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter9szqlu = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
setVariant("Kq8NtCRh2")
})

const onTappu0pf4 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (Kfe0qRoJD) {const res = await Kfe0qRoJD(...args);
if (res === false) return false;}
})

const onMouseLeaveq8xjra = activeVariantCallback(async (...args) => {
setGestureState({isHovered: false})
setVariant("eMuKVzvgJ")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-995dky", className, classNames)} data-framer-name={"Small"} data-highlight layoutDependency={layoutDependency} layoutId={"eMuKVzvgJ"} onMouseEnter={onMouseEnter9szqlu} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({kHNnaqIzo: {"data-framer-name": "Open", "data-highlight": undefined, onMouseEnter: undefined}, Kq8NtCRh2: {"data-framer-name": "Medium", onMouseEnter: undefined, onMouseLeave: onMouseLeaveq8xjra, onTap: onTappu0pf4}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-wk10cp-container"} layoutDependency={layoutDependency} layoutId={"pQ5otxlDk-container"}><Card height={"100%"} id={"pQ5otxlDk"} layoutId={"pQ5otxlDk"} name={pZ18UUIqg} portrait={toResponsiveImage(BpqMX3cRk)} role={N8vYS_fKJ} style={{height: "100%", maxWidth: "100%"}} variant={"kXYaJPvPX"} width={"100%"} {...addPropertyOverrides({kHNnaqIzo: {variant: "JRWTetSUI"}, Kq8NtCRh2: {variant: "jUMgGAwHb"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oYGjw.framer-1fjaakw, .framer-oYGjw .framer-1fjaakw { display: block; }", ".framer-oYGjw.framer-995dky { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 640px; justify-content: center; overflow: visible; padding: 0px 10px 0px 10px; position: relative; width: min-content; }", ".framer-oYGjw .framer-wk10cp-container { flex: none; height: 640px; max-width: 610px; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-oYGjw.framer-995dky { gap: 0px; } .framer-oYGjw.framer-995dky > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-oYGjw.framer-995dky > :first-child { margin-left: 0px; } .framer-oYGjw.framer-995dky > :last-child { margin-right: 0px; } }", ".framer-oYGjw.framer-v-1mhnes2.framer-995dky { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 640
 * @framerIntrinsicWidth 110
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"Kq8NtCRh2":{"layout":["auto","fixed"]},"kHNnaqIzo":{"layout":["auto","fixed"]}}}
 * @framerVariables {"Kfe0qRoJD":"newOpen","BpqMX3cRk":"portrait","N8vYS_fKJ":"role","pZ18UUIqg":"name1"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDf9FqP0tu: React.ComponentType<Props> = withCSS(Component, css, "framer-oYGjw") as typeof Component;
export default FramerDf9FqP0tu;

FramerDf9FqP0tu.displayName = "Card-Wrapped";

FramerDf9FqP0tu.defaultProps = {height: 640, width: 110};

addPropertyControls(FramerDf9FqP0tu, {variant: {options: ["eMuKVzvgJ", "Kq8NtCRh2", "kHNnaqIzo"], optionTitles: ["Small", "Medium", "Open"], title: "Variant", type: ControlType.Enum}, Kfe0qRoJD: {title: "New Open", type: ControlType.EventHandler}, BpqMX3cRk: {__defaultAssetReference: "data:framer/asset-reference,MOjeMBfvtTEBPdcYZSWnOfyjkY.png?originalFilename=image.png&preferredSize=auto", title: "Portrait", type: ControlType.ResponsiveImage}, N8vYS_fKJ: {defaultValue: "PRESIDENT, PRODUCT AND BUSINESS, STRIPE", displayTextArea: false, title: "Role", type: ControlType.String}, pZ18UUIqg: {defaultValue: "Will Gaybrick", displayTextArea: false, title: "Name", type: ControlType.String}} as any)

addFonts(FramerDf9FqP0tu, [{explicitInter: true, fonts: []}, ...CardFonts], {supportsExplicitInterCodegen: true})